import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { Permission } from './core/enums/permissions';
import { AuthGuard } from './auth/auth.guard';
import { BudgetType } from './core/enums/budget-type';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: 'auth',
                loadChildren: () =>
                    import('src/app/auth/auth.module').then((m) => m.AuthModule),
                canLoad: [AuthGuard],
                data: { redirectTo: '/dashboard' },
            },
            {
                path: '',
                loadChildren: () =>
                    import('src/app/auth/auth.module').then((m) => m.AuthModule),
                canLoad: [AuthGuard],
                data: { redirectTo: '/dashboard' },
            },
            {
                path: 'dashboard', pathMatch: 'full',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                loadChildren: () => import('src/app/pages/dashboard/dashboard.module')
                    .then(m => m.DashboardModule)
            },
            {
                path: 'account',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                loadChildren: () => import('src/app/pages/account/account.module')
                    .then(m => m.AccountModule)
            },
            {
                path: 'company',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                loadChildren: () => import('src/app/pages/company/company.module')
                    .then(m => m.CompanyModule)
            },
            {
                path: 'customers',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                loadChildren: () => import('src/app/pages/customers/customers.module')
                    .then(m => m.CustomersModule)
            },
            {
                path: 'contacts',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                loadChildren: () => import('src/app/pages/contacts/contacts.module')
                    .then(m => m.ContactsModule)
            },
            {
                path: 'teachers',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                loadChildren: () => import('src/app/pages/teachers/teachers.module')
                    .then(m => m.TeachersModule)
            },
            {
                path: 'students',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.TrainingManager,Permission.VCA],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/students/students.module')
                    .then(m => m.StudentsModule)
            },
            {
                path: 'course-templates',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                loadChildren: () => import('src/app/pages/course-templates/course-templates.module')
                    .then(m => m.CourseTemplatesModule)
            },
            {
                path: 'courses',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                loadChildren: () => import('src/app/pages/courses/courses.module')
                    .then(m => m.CoursesModule)
            },
            {
                path: 'enrollments',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                loadChildren: () => import('src/app/pages/enrollments/enrollments.module')
                    .then(m => m.EnrollmentsModule)
            },
            {
                path: 'cert-images',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.TrainingManager],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/cert-images/cert-images.module')
                    .then(m => m.CertImagesModule)
            },
            {
                path: 'careers',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.JobExchange],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/careers/careers.module')
                    .then(m => m.CareersModule)
            },
            {
                path: 'career-levels',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.JobExchange],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/career-levels/career-levels.module')
                    .then(m => m.CareerLevelsModule)
            },
            {
                path: 'regulated-trainings',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.JobExchange],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/regulated-trainings/regulated-trainings.module')
                    .then(m => m.RegulatedTrainingsModule)
            },
            {
                path: 'non-regulated-trainings',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.JobExchange],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/non-regulated-trainings/non-regulated-trainings.module')
                    .then(m => m.NonRegulatedTrainingsModule)
            },
            {
                path: 'skills',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.JobExchange],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/skills/skills.module')
                    .then(m => m.SkillsModule)
            },
            {
                path: 'languages',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.JobExchange],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/languages/languages.module')
                    .then(m => m.LanguagesModule)
            },
            {
                path: 'workers',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.JobExchange],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/workers/workers.module')
                    .then(m => m.WorkersModule)
            },
            {
                path: 'job-positions',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.JobExchange],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/job-positions/job-positions.module')
                    .then(m => m.JobPositionsModule)
            },

            {
                path: 'vca-students',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.VCA],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/vca-students/vca-students.module')
                    .then(m => m.VcaStudentsModule)
            },
            {
                path: 'vca-exams',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.VCA],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/vca-exams/vca-exams.module')
                    .then(m => m.VcaExamsModule)
            },
            {
                path: 'vca-enrollments',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.VCA],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/vca-enrollments/vca-enrollments.module')
                    .then(m => m.VcaEnrollmentsModule)
            },
            {
                path: 'vca-languages',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.VCA],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/languages/languages.module')
                    .then(m => m.LanguagesModule)
            },
            {
                path: 'vca-teachers',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.VCA],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/teachers/teachers.module')
                    .then(m => m.TeachersModule)
            },
            {
                path: 'vca-places',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.VCA],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/vca-places/vca-places.module')
                    .then(m => m.VcaPlacesModule)
            },
            {
                path: 'training-budgets',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.TrainingManagerBudgets],
                        redirectTo: '/access'
                    },
                    budgetType: BudgetType.Training
                },
                loadChildren: () => import('src/app/pages/budgets/budgets.module')
                    .then(m => m.BudgetsModule)
            },
            {
                path: 'vca-budgets',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.VCABudgets],
                        redirectTo: '/access'
                    },
                    budgetType: BudgetType.VCA
                },
                loadChildren: () => import('src/app/pages/budgets/budgets.module')
                    .then(m => m.BudgetsModule)
            },
            {
                path: 'budget-materials',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.VCABudgets],
                        redirectTo: '/access'
                    },
                    budgetType: BudgetType.VCA
                },
                loadChildren: () => import('src/app/pages/budget-materials/budget-materials.module')
                    .then(m => m.BudgetMaterialsModule)
            },
            {
                path: 'users',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.Admin],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/users/users.module')
                    .then(m => m.UsersModule)
            },
            {
                path: 'tenants',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.Admin],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/admin-companies/admin-companies.module')
                    .then(m => m.AdminCompaniesModule)
            },
            {
                path: 'logs',
                component: AppMainComponent,
                canLoad: [AuthGuard],
                data: {
                    permissions: {
                        only: [Permission.Admin],
                        redirectTo: '/access'
                    }
                },
                loadChildren: () => import('src/app/pages/app-usage/app-usage.module')
                    .then(m => m.AppUsageModule)
            },
            {
                path: 'error',
                canLoad: [AuthGuard],
                component: AppErrorComponent
            },
            { path: 'access', component: AppAccessdeniedComponent },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: '**', redirectTo: '' },
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
