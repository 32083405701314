<ng-container>
    <div *ngIf="root" class="layout-menuitem-root-text">{{item.label}}</div>
    <a [attr.href]="item.url" (click)="itemClick($event)" *ngIf="!item.routerLink || item.items"
       (mouseenter)="onMouseEnter()" (keydown.enter)="itemClick($event)"
       [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class" pRipple>
        <fa-icon *ngIf="item.iconFamily == 'fontawesome'" [icon]="['fas', item.icon]"></fa-icon>
        <i *ngIf="item.iconFamily != 'fontawesome'" [ngClass]="item.icon" class="layout-menuitem-icon"></i>
        <span class="layout-menuitem-text">{{item.label}}</span>
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
        <span class="menuitem-badge" *ngIf="item.badge">{{item.badge}}</span>
    </a>
    <a (click)="itemClick($event)" (mouseenter)="hover=true" (mouseleave)="hover=false"
       *ngIf="item.routerLink && !item.items"
        [routerLink]="item.routerLink" routerLinkActive="active-menuitem-routerlink"
       [routerLinkActiveOptions]="{exact: false }" [attr.target]="item.target" [attr.tabindex]="0"
       [ngClass]="item.class" pRipple>
        <fa-icon *ngIf="item.iconFamily == 'fontawesome'" [icon]="['fas', item.icon]"></fa-icon>
        <i *ngIf="item.iconFamily != 'fontawesome'" [ngClass]="item.icon"  class="layout-menuitem-icon"></i>
        <span class="layout-menuitem-text">{{item.label}}</span>
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items" ></i>
        <span class="menuitem-badge" *ngIf="item.badge" >{{item.badge}}</span>
    </a>
    <ul *ngIf="(item.items && active) || (item.items && app.isStatic() && (root || active))" [@children]="(
    (app.isSlim()||app.isHorizontal()) && !app.isMobile() && root) ?
    (active ? 'visible' : 'hidden') :
    (app.isStatic() && root && !app.isMobile() ? 'visible' : (active ? 'visibleAnimated' : 'hiddenAnimated'))">
        <ng-template ngFor let-child let-i="index" [ngForOf]="item.items" >
                <li *ngxPermissionsOnly="child.roles" app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass" ></li>
        </ng-template>
    </ul>
</ng-container>
