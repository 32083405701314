import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuService } from './app.menu.service';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
    /* tslint:disable:component-selector */
    selector: '[app-menuitem]',
    /* tslint:enable:component-selector */
    templateUrl: './app.menuitem.component.html',
    host: {
        '[class.layout-root-menuitem]': 'root',
        '[class.active-menuitem]': 'active'
    }
})
export class AppMenuitemComponent implements OnInit, OnDestroy {
    @Input() item: any;
    @Input() index: number;
    @Input() root: boolean;
    @Input() parentKey: string;

    active = false;
    hover: boolean;
    menuSourceSubscription: Subscription;
    menuResetSubscription: Subscription;
    key: string;

    constructor(public app: AppMainComponent, public router: Router, private cd: ChangeDetectorRef, private menuService: MenuService) {
        this.menuSourceSubscription = this.menuService.menuSource$.subscribe(key => {
            // deactivate current active menu
            if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
                this.active = false;
            }
        });

        this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
            this.active = false;
        });

        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(params => {
                if ((this.app.isSlim() || this.app.isHorizontal()) && !this.app.isMobile()) {
                    this.active = false;
                } else {
                    if (this.item.routerLink) {
                        this.updateActiveStateFromRoute();
                    } else {
                        this.active = false;
                    }
                }
            });
    }

    ngOnInit() {
        if (!(this.app.isSlim() || this.app.isHorizontal()) && this.item.routerLink) {
            this.updateActiveStateFromRoute();
        }

        this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);
    }

    updateActiveStateFromRoute() {

        this.active = this.router.isActive(this.item.routerLink[0], !this.item.items);
        if (!this.active) {
            let urlPart = `/${this.router.url.split("/")[1]}`;
            if (!this.item.items && urlPart == this.item.routerLink[0]
                && this.item.routerLink[0] != "/") {
                this.active = true;
            }
            else if (this.item.items && this.item.items.findIndex(x => x.routerLink[0] != "/"
                && urlPart.indexOf(x.routerLink[0]) !== -1) >= 0) {
                this.active = true;
            }
        }
    }

    itemClick(event: Event) {
        // avoid processing disabled items
        if (this.item.disabled) {
            event.preventDefault();
            return true;
        }

        // navigate with hover in horizontal mode
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }

        // notify other items
        this.menuService.onMenuStateChange(this.key);

        // execute command
        if (this.item.command) {
            this.item.command({ originalEvent: event, item: this.item });
        }

        // toggle active state
        if (this.item.items) {
            this.active = !this.active;
        }
        else {
            // activate item
            this.active = true;
            if (this.app.isMobile()) {
                this.app.staticMenuMobileActive = false;
            }

            if (this.app.isOverlay()) {
                this.app.overlayMenuActive = false;
            }

            // reset horizontal menu
            if ((this.app.isSlim() || this.app.isHorizontal()) && !this.app.isMobile()) {
                this.menuService.reset();
                this.app.menuHoverActive = false;
            }
        }
    }

    onMouseEnter() {
        // activate item on hover
        if (this.root && this.app.menuHoverActive && (this.app.isSlim() || this.app.isHorizontal()) && this.app.isDesktop()) {
            this.menuService.onMenuStateChange(this.key);
            this.active = true;
        }
    }

    ngOnDestroy() {
        if (this.menuSourceSubscription) {
            this.menuSourceSubscription.unsubscribe();
        }

        if (this.menuResetSubscription) {
            this.menuResetSubscription.unsubscribe();
        }
    }
}
