import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Injectable()
export class AppBreadcrumbService {

    private itemsSource = new Subject<MenuItem[]>();

    itemsHandler = this.itemsSource.asObservable();

    setItems(items: MenuItem[]) {
        if (localStorage.getItem('admincompany') !== null) {
            let company = JSON.parse(localStorage.getItem('admincompany'))
            items.unshift({
                label: `(${company.tradeName})`,
                routerLink: ['/tenants']
            })
        }
        this.itemsSource.next(items);
    }
}
