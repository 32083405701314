export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  api: 'https://api.iformacion.net',
  firebase: {
    apiKey: "AIzaSyAzrAz8daDGsAWZ46haymYYO_DQa25a87g",
    authDomain: "iformacion-pro.firebaseapp.com",
    projectId: "iformacion-pro",
    storageBucket: "iformacion-pro.appspot.com",
    messagingSenderId: "217740672218",
    appId: "1:217740672218:web:9ed9494fe898aa428639e3"
  },
  useEmulators: false

};
