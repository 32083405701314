import { Injectable } from '@angular/core';
import { Router, Route, UrlSegment, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getUserRoles } from '../core/functions';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router
  ) { }
  canLoad(route: Route, segments: UrlSegment[])
    : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    return this.afAuth.idTokenResult.pipe(
      map(userData => {
        if (userData == null) {
          if (route.path == 'auth' && segments[0].path == 'auth')
            return true;

          this.router.navigate(['/auth']);
          return false;
        }

        if (route.data?.permissions != null) {
          let roles = getUserRoles(userData.claims);
          return this.checkUserRoles(roles, route)
        }

        if (!segments.length) {
          if (route.data?.redirectTo)
            this.router.navigate([route.data.redirectTo])
          return true;
        } else {
          if (route.path == segments[0].path)
            if (route.data?.redirectTo) {
              this.router.navigate([route.data.redirectTo])
            }
          return true;
        }
      })
    );
  }

  private checkUserRoles(roles, route: Route) {
    let isOkRoles = false;
    let targetRoles = route.data.permissions.only as Array<string>;

    if (roles && targetRoles) {
      // Se guarda la lista de roles a comprobar en un array para facilitar su manejo
      let rolesArray = Array.isArray(targetRoles) ? targetRoles : [targetRoles];
      isOkRoles = roles.some(x => rolesArray.includes(x));
    }

    if (isOkRoles) {
      return true;
    }
    else {
      console.log("USUARIO SIN PERMISOS")
      if (route.data.permissions.redirectTo)
        this.router.navigate([route.data.permissions.redirectTo])
      else
        this.router.navigate(['/access']);

      return false;
    }
  }
}
