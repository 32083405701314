import './shared/string.extensions'
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import * as Sentry from "@sentry/angular-ivy";

import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { CarouselModule } from 'primeng/carousel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ChartModule } from 'primeng/chart';
import { AvatarModule } from 'primeng/avatar';
import { AccordionModule } from 'primeng/accordion';

// Application Components
import { AppComponent, createTranslateLoader } from './app.component';
import { AppBreadcrumbComponent } from 'src/app/layout/breadcrumb/app.breadcrumb.component';
import { AppMainComponent } from './app.main.component';
import { AppRightMenuComponent } from './layout/rightmenu/app.rightmenu.component';
import { AppInlineMenuComponent } from './layout/inlinemenu/app.inlinemenu.component';
import { AppMenuComponent } from './layout/menu/app.menu.component';
import { AppMenuitemComponent } from './layout/menu/app.menuitem.component';
import { AppTopbarComponent } from './layout/topbar/app.topbar.component';
import { AppFooterComponent } from './layout/footer/app.footer.component';

import { AppHelpComponent } from './pages/app.help.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';

// Application services
import { MenuService } from './layout/menu/app.menu.service';
import { AppBreadcrumbService } from './layout/breadcrumb/app.breadcrumb.service';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import localeEs from '@angular/common/locales/es';
import localeExtraEs from '@angular/common/locales/extra/es';
import { registerLocaleData } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { BlobErrorHttpInterceptor } from './shared/blob-error.http-interceptor';
import { VersionsModule } from './pages/versions/versions.module';
// import { PermissionsGuard } from './auth/permissions-guard';
import { AuthService } from './auth/auth.service';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { TokenInterceptor } from './auth/auth.interceptor';

registerLocaleData(localeEs, 'es-ES', localeExtraEs);

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    ButtonModule,
    CardModule,
    TableModule,
    CarouselModule,
    ProgressBarModule,
    ChartModule,
    DropdownModule,
    AvatarModule,
    AccordionModule,
    CalendarModule,
    InputTextModule,
    InputNumberModule,
    InputSwitchModule,
    ToastModule,
    FontAwesomeModule,
    AvatarModule,
    DialogModule,
    VersionsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  declarations: [
    AppComponent,
    AppBreadcrumbComponent,
    AppMainComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppRightMenuComponent,
    AppInlineMenuComponent,
    AppTopbarComponent,
    AppFooterComponent,
    AppHelpComponent,
    AppNotfoundComponent,
    AppErrorComponent,
    AppAccessdeniedComponent
  ],
  providers: [
    AuthService,
    MenuService,
    AppBreadcrumbService,
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: BlobErrorHttpInterceptor,
    //   multi: true
    // },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: "es-ES"
    },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost', 29099] : undefined },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIconPacks(fas, far);
  }
}
