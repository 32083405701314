import { Injectable, NgZone } from '@angular/core';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
    AngularFirestore,
    AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { getUserRoles } from '../core/functions';
import { Permission } from '../core/enums/permissions';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    userData: any; // Save logged in user data
    constructor(
        public afs: AngularFirestore, // Inject Firestore service
        public afAuth: AngularFireAuth, // Inject Firebase auth service
        public router: Router,
        public ngZone: NgZone, // NgZone service to remove outside scope warning
    ) {
        /* Saving user data in localstorage when
        logged in and setting up null when logged out */
        this.afAuth.authState.subscribe((user) => {
            if (user) {
                this.userData = user;
                localStorage.setItem('user', JSON.stringify(this.userData));
                JSON.parse(localStorage.getItem('user')!);
            } else {
                localStorage.setItem('user', 'null');
                JSON.parse(localStorage.getItem('user')!);
            }
        });
    }
    // Sign in with email/password
    SignIn(email: string, password: string) {
        return this.afAuth
            .signInWithEmailAndPassword(email, password)
            .then((result) => {
                this.SetUserData(result.user);
                this.afAuth.idTokenResult.subscribe((user) => {
                    if (user) {
                        if (getUserRoles(user.claims).includes(Permission.Admin))
                            this.router.navigate(['tenants']);
                        else
                            this.router.navigate(['dashboard']);
                    }
                });
            })
            .catch((error) => {
                window.alert(error.message);
            });
    }
    // Sign up with email/password
    SignUp(email: string, password: string) {
        return this.afAuth
            .createUserWithEmailAndPassword(email, password)
            .then((result) => {
                /* Call the SendVerificaitonMail() function when new user sign
                up and returns promise */
                this.SendVerificationMail();
                this.SetUserData(result.user);
            })
            .catch((error) => {
                window.alert(error.message);
            });
    }
    // Send email verfificaiton when new user sign up
    SendVerificationMail() {
        return this.afAuth.currentUser
            .then((u: any) => u.sendEmailVerification())
            .then(() => {
                this.router.navigate(['verify-email-address']);
            });
    }
    // Reset Forggot password
    ForgotPassword(passwordResetEmail: string) {
        return this.afAuth
            .sendPasswordResetEmail(passwordResetEmail)
            .then(() => {
                window.alert('Password reset email sent, check your inbox.');
            })
            .catch((error) => {
                window.alert(error);
            });
    }
    // Returns true when user is looged in and email is verified
    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user')!);
        return user !== null;
    }
    // Sign in with Google
    GoogleAuth() {
        return this.AuthLogin(new auth.GoogleAuthProvider()).then((res: any) => {
            this.router.navigate(['dashboard']);
        });
    }
    // Auth logic to run auth providers
    AuthLogin(provider: any) {
        return this.afAuth
            .signInWithPopup(provider)
            .then((result) => {
                this.router.navigate(['dashboard']);
                this.SetUserData(result.user);
            })
            .catch((error) => {
                window.alert(error);
            });
    }
    /* Setting up user data when sign in with username/password,
    sign up with username/password and sign in with social auth
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
    SetUserData(user: any) {
        // const userRef: AngularFirestoreDocument<any> = this.afs.doc(
        //     `users/${user.uid}`
        // );
        // const userData: User = {
        //     uid: user.uid,
        //     email: user.email,
        //     displayName: user.displayName,
        //     photoURL: user.photoURL,
        //     emailVerified: user.emailVerified,
        // };
        // return userRef.set(userData, {
        //     merge: true,
        // });
    }
    // Sign out
    SignOut() {
        return this.afAuth.signOut().then(() => {
            localStorage.removeItem('user');
            localStorage.removeItem('admincompany');
            this.router.navigate(['sign-in']);
        });
    }


    // Método que obtiene los roles del usuario de la sesión y los carga a través de la librería NgxPermissions.
    // Esto les permite tenerlos disponibles en las vistas html y poder gestionar la visibilidad de los diferentes componentes del html en base a los roles
    public static LoadUserRoles(user: any, permissionsService: NgxPermissionsService) {

        var userData = user;
        if (userData) {
            const perm = userData;
            if (perm) {
                if (Array.isArray(perm)) {
                    permissionsService.loadPermissions(perm);
                }
                else {
                    permissionsService.loadPermissions([perm]);
                }
                // Almacenamos los permisos del usuario en el localStorage para tenerlos disponibles
                window.localStorage.setItem("permissions", JSON.stringify(perm));
            }
        }
    }

    hasRoles(targetRoles: string[]) {
        if (!this.userData)
            return false;

        //obtenemos el rol del usuario que puede ser uno o varios roles
        const perm = Array.isArray(this.userData) ? this.userData : [this.userData];
        if (perm && targetRoles) {
            // Se guarda la lista de roles a comprobar en un array para facilitar su manejo
            const rolesArray = Array.isArray(targetRoles) ? targetRoles : [targetRoles];
            return perm.some((x) => rolesArray.includes(x));
        }

        return false;
    }
}
