import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from 'src/app/app.main.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AuthService } from 'src/app/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User } from 'firebase/auth';
import { getDisplayName, getInitials, getUserRoles } from 'src/app/core/functions';
import { Permission } from 'src/app/core/enums/permissions';

@Component({
    selector: 'app-inlinemenu',
    templateUrl: './app.inlinemenu.component.html',
    animations: [
        trigger('inline', [
            state('hidden', style({
                height: '0px',
                overflow: 'hidden'
            })),
            state('visible', style({
                height: '*',
            })),
            state('hiddenAnimated', style({
                height: '0px',
                overflow: 'hidden'
            })),
            state('visibleAnimated', style({
                height: '*',
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppInlineMenuComponent implements OnInit {
    user: User;
    avatar: string;
    displayName: string;
    isAdmin: boolean;

    constructor(
        public appMain: AppMainComponent,
        public afAuth: AngularFireAuth,
        public authService: AuthService) {

        this.afAuth.authState.subscribe((user) => {
            if (user) {
                this.user = user;
                this.avatar = getInitials(this.user);
                this.displayName = getDisplayName(this.user);
            }
        });
    }

    ngOnInit(): void {
        this.afAuth.idTokenResult.subscribe((user) => {
            if (user) {
                this.isAdmin = getUserRoles(user.claims).includes(Permission.Admin);
            }
        });
    }

    logout() {
        this.authService.SignOut();
    }
}
