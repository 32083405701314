export { }

declare global {
    interface String {
        elipse(length: number): string;
    }
}

String.prototype.elipse = function (maxLength: number) {
    let d = String(this);
    if (d.length > maxLength) {
        return `${d.substring(0, maxLength - 3)}...`;
    }
    return d;
}