import { Injectable } from '@angular/core';
import LogRocket from 'logrocket';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class LogRocketService {

    user: any;

    constructor(private authService: AuthService) { }

    init() {
        LogRocket.init('zten9a/iformacion');
    }

    setUserId() {
        this.authService.userData?.subscribe(userData => {
            if (userData.userData) {
                LogRocket.identify(userData.userData.sub, {
                    name: userData.userData.given_name,
                    email: userData.userData.emails[0]
                });
            }
        });
    }
}
