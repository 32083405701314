<div class="layout-breadcrumb-container flex align-items-center justify-content-between">
    <div class="layout-breadcrumb-left-items flex align-items-center">
        <a href="#" class="menu-button" (click)="appMain.onMenuButtonClick($event)"
            *ngIf="appMain.isStatic() && !appMain.isMobile()">
            <i class="pi pi-bars"></i>
        </a>

        <p-breadcrumb [model]="items" styleClass="layout-breadcrumb p-py-2"></p-breadcrumb>
    </div>
    <div class="layout-breadcrumb-right-items flex">
        <a tabindex="0" class="search-icon" (click)="appMain.searchActive = true; appMain.searchClick = true;">
            <i class="pi pi-search"></i>
        </a>

        <!--<div class="search-wrapper" [ngClass]="{'active-search-wrapper': appMain.searchActive}">
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" pInputText placeholder="Search..." [(ngModel)]="search" (click)="appMain.searchClick=true"/>
            </span>
        </div>-->

        <button pButton pRipple type="button" label="Hoy" icon="pi pi-bookmark" *ngIf="!appMain.isMobile()"
            class="layout-rightmenu-button" (click)="appMain.onRightMenuButtonClick()"></button>
        <button pButton pRipple type="button" icon="pi pi-bookmark" *ngIf="appMain.isMobile()"
            class="layout-rightmenu-button" (click)="appMain.onRightMenuButtonClick()"></button>
    </div>
</div>