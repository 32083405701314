import { HttpParams } from "@angular/common/http";
import { LazyLoadEvent } from "primeng/api";

export class HttpParamsBuilder {
    //in a real application, make a remote request to load data using state metadata from event
    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    public static fromLazyEvent(e: LazyLoadEvent): HttpParams {
        return new HttpParams()
            .set("searchTerm", e.globalFilter ? e.globalFilter : '')
            .set("orderBy", e.sortField ? e.sortField : 'id')
            .set("order", this.mapOrder(e.sortOrder))
            .set("index", (e.first / e.rows + 1))
            .set("size", e.rows ? e.rows : 10)
    }

    public static fromIds(ids: string[]): HttpParams {
        let params: HttpParams = new HttpParams();
        for (let i in ids) {
            params = params.append("ids", ids[i]);
        }
        return params;
    }

    public static appendParamsInArray(params: HttpParams, name: string, values: string[]) {
        for (let i in values) {
            params = params.append(name, values[i]);
        }
        return params;
    }

    private static mapOrder(order: number): OrderBy {
        return order > 0 ? OrderBy.Asc : OrderBy.Desc;
    }
}
enum OrderBy {
    Asc = "asc",
    Desc = "desc"
}
