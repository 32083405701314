import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { browserTracingIntegration, init } from '@sentry/angular-ivy';

init({
    dsn: 'https://ecfc57ff23684862b08c311deebb9d60@o92209.ingest.sentry.io/5944286',
    integrations: [
        browserTracingIntegration(),
    ],
    tracePropagationTargets: ['localhost', 'https://dev.api.iformacion.net', 'https://api.iformacion.net'],
    tracesSampleRate: 1,
});

if (environment.production) {


    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
