import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
@Injectable({
    providedIn: 'root'
})
export class FreshchatService {

    user: any;

    constructor(private authService: AuthService) { }

    init() {
        (<any>window).fcWidget.init({
            token: "3c673106-5894-4a99-8462-d62458a837e5",
            host: "https://wchat.freshchat.com",
            siteId: "iformacion"
        });
    }

    setUserId() {
        // Copy the below lines under window.fcWidget.init inside initFreshChat function in the above snippet

        this.authService.userData?.subscribe(userData => {

            if (userData && userData.userData) {
                // To set unique user id in your system when it is available
                (<any>window).fcWidget?.setExternalId(userData.userData.sub);

                // To set user name
                (<any>window).fcWidget?.user.setFirstName(userData.userData.given_name);

                // To set user email
                (<any>window).fcWidget?.user.setEmail(userData.userData.emails[0]);
            }
        });
    }
}
