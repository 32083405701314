import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppComponent } from 'src/app/app.component';
import { AppMainComponent } from 'src/app/app.main.component';
import { getUserRoles } from 'src/app/core/functions';
import { Permission } from 'src/app/core/enums/permissions';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
    public model: any[];

    constructor(public app: AppComponent,
        public appMain: AppMainComponent,
        public afAuth: AngularFireAuth,
        public authService: AuthService,
        private permissionsService: NgxPermissionsService) {

    }

    ngOnInit() {
        this.afAuth.idTokenResult.subscribe((user) => {
            if (user) {
                const permissions = getUserRoles(user.claims);
                this.permissionsService.loadPermissions(permissions);
            }
        });

        this.model = [
            {
                label: 'Dashboard',
                icon: 'pi pi-fw pi-th-large',
                routerLink: ['/dashboard'],
                show: true
            },
            {
                label: 'Empresa',
                iconFamily: 'fontawesome',
                icon: 'building',
                routerLink: ['/company'],
                show: true
            },

            {
                label: 'Clientes',
                iconFamily: 'fontawesome',
                icon: 'user-tie',
                routerLink: ['/customers'],
                roles: [Permission.TrainingManager, Permission.JobExchange],
                show: true
            },
            {
                label: 'Contactos',
                icon: 'pi pi-fw pi-id-card',
                routerLink: ['/contacts'],
                roles: [Permission.TrainingManager, Permission.JobExchange],
                show: true
            },
            {
                label: 'Formación',
                iconFamily: 'fontawesome',
                icon: 'graduation-cap',
                routerLink: ['/training'],
                roles: [Permission.TrainingManager],
                items: [
                    {
                        label: 'Formadores',
                        iconFamily: 'fontawesome',
                        icon: 'chalkboard-teacher',
                        routerLink: ['/teachers'],
                        roles: [Permission.TrainingManager],
                        show: true
                    },
                    {
                        label: 'Alumnos',
                        icon: 'pi pi-fw pi-users',
                        routerLink: ['/students'],
                        roles: [Permission.TrainingManager],
                        show: true
                    },
                    {
                        label: 'Plantillas',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/course-templates'],
                        roles: [Permission.TrainingManager],
                        show: true
                    },
                    {
                        label: 'Cursos',
                        icon: 'pi pi-fw pi-calendar',
                        routerLink: ['/courses'],
                        roles: [Permission.TrainingManager],
                        show: true
                    },
                    {
                        label: 'Inscritos',
                        icon: 'pi pi-fw pi-check-circle',
                        routerLink: ['/enrollments'],
                        roles: [Permission.TrainingManager],
                        show: true
                    },
                    {
                        label: 'Logos',
                        icon: 'pi pi-fw pi-images',
                        routerLink: ['/cert-images'],
                        roles: [Permission.TrainingManager],
                        show: true
                    }

                ],

                show: true
            },
            {
                label: 'Bolsa empleo',
                icon: 'pi pi-briefcase',
                routerLink: ['/job'],
                roles: [Permission.JobExchange],
                items: [{
                    label: 'Profesiones',
                    iconFamily: 'fontawesome',
                    icon: 'user-tie',
                    routerLink: ['/careers'],
                    roles: [Permission.JobExchange],
                    show: true
                },
                {
                    label: 'Categorías',
                    iconFamily: 'fontawesome',
                    icon: 'boxes',
                    routerLink: ['/career-levels'],
                    roles: [Permission.JobExchange],
                    show: true
                },
                {
                    label: 'Formaciones regladas',
                    iconFamily: 'fontawesome',
                    icon: 'user-graduate',
                    routerLink: ['/regulated-trainings'],
                    roles: [Permission.JobExchange],
                    show: true
                },
                {
                    label: 'Formaciones no regladas',
                    iconFamily: 'fontawesome',
                    icon: 'graduation-cap',
                    routerLink: ['/non-regulated-trainings'],
                    roles: [Permission.JobExchange],
                    show: true
                },
                {
                    label: 'Capacidades',
                    iconFamily: 'fontawesome',
                    icon: 'medal',
                    routerLink: ['/skills'],
                    roles: [Permission.JobExchange],
                    show: true
                },
                {
                    label: 'Idiomas',
                    iconFamily: 'fontawesome',
                    icon: 'language',
                    routerLink: ['/languages'],
                    roles: [Permission.JobExchange],
                    show: true
                },
                {
                    label: 'Trabajadores',
                    icon: 'pi pi-fw pi-users',
                    routerLink: ['/workers'],
                    roles: [Permission.JobExchange],
                    show: true
                },
                {
                    label: 'Ofertas',
                    iconFamily: 'fontawesome',
                    icon: 'magnifying-glass-chart',
                    routerLink: ['/job-positions'],
                    roles: [Permission.JobExchange],
                    show: true
                }
                ],
                show: true
            },
            {
                label: 'VCA',
                iconFamily: 'fontawesome',
                icon: 'clipboard-user',
                routerLink: ['/vca'],
                roles: [Permission.VCA],
                items: [
                    {
                        label: 'Formadores',
                        iconFamily: 'fontawesome',
                        icon: 'chalkboard-teacher',
                        routerLink: ['/vca-teachers'],
                        roles: [Permission.VCA],
                        show: true
                    },
                    {
                        label: 'Alumnos',
                        icon: 'pi pi-fw pi-users',
                        routerLink: ['/vca-students'],
                        roles: [Permission.VCA],
                        show: true
                    },
                    {
                        label: 'Exámenes',
                        icon: 'pi pi-fw pi-calendar',
                        routerLink: ['/vca-exams'],
                        roles: [Permission.VCA],
                        show: true
                    },
                    {
                        label: 'Inscritos',
                        icon: 'pi pi-fw pi-check-circle',
                        routerLink: ['/vca-enrollments'],
                        roles: [Permission.VCA],
                        show: true
                    },
                    {
                        label: 'Idiomas',
                        iconFamily: 'fontawesome',
                        icon: 'language',
                        routerLink: ['/vca-languages'],
                        roles: [Permission.VCA],
                        show: true
                    },
                    {
                        label: 'Lugares',
                        iconFamily: 'fontawesome',
                        icon: 'location-dot',
                        routerLink: ['/vca-places'],
                        roles: [Permission.VCA],
                        show: true
                    },
                ],
                show: true
            },
            {
                label: 'Presupuestos',
                iconFamily: 'fontawesome',
                icon: 'clipboard-user',
                routerLink: ['/budgets'],
                roles: [Permission.VCABudgets, Permission.TrainingManagerBudgets],
                items: [
                    {
                        label: 'Formación',
                        iconFamily: 'fontawesome',
                        icon: 'chalkboard-teacher',
                        routerLink: ['/training-budgets'],
                        roles: [Permission.TrainingManagerBudgets],
                        show: true
                    },
                    {
                        label: 'VCA',
                        iconFamily: 'fontawesome',
                        icon: 'chalkboard-teacher',
                        routerLink: ['/vca-budgets'],
                        roles: [Permission.VCABudgets],
                        show: true
                    },
                    {
                        label: 'Materiales',
                        iconFamily: 'fontawesome',
                        icon: 'chalkboard-teacher',
                        routerLink: ['/budget-materials'],
                        roles: [Permission.VCABudgets, Permission.TrainingManagerBudgets],
                        show: true
                    },
                ],
                show: true
            },
            {
                label: 'Usuarios',
                iconFamily: 'fontawesome',
                icon: 'users-cog',
                routerLink: ['/users'],
                roles: [Permission.Admin],
                show: true
            },
            {
                label: 'Logs',
                iconFamily: 'fontawesome',
                icon: 'rectangle-list',
                routerLink: ['/logs'],
                roles: [Permission.Admin],
                show: true
            }
        ];
    }
}
